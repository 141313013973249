$themeBlue: #005cff;
$themeBlack: #0c2344;

$scrollbar-width: 1px;
// $scrollbar-thumb-color: #d6cccc  ;
$scrollbar-thumb-color: #f1f1f1;
$scrollbar-track-color: #f1f1f1;

@mixin scrollbar-style {
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  & {
    scrollbar-width: thin;
  }

  &::-moz-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
  }

  &::-moz-scrollbar-track {
    background-color: $scrollbar-track-color;
  }
}

.header {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  .row {
    align-items: center;
  }
  img {
    @media only screen and (max-width: 600px) {
      width: 6rem;
    }
  }
  .download-button {
    background: $themeBlue;
    color: white;
    border: none;
    padding: 12px 35px;
    box-shadow: 0px 5px 24px rgba(33, 97, 246, 0.2);
    border-radius: 8px;
    float: right;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-weight: normal;
    width: 160px;
    height: 48px;
    @media only screen and (max-width: 600px) {
      line-height: 100%;
      letter-spacing: 0.01em;
      font-size: 14px;
      width: 125px;
      height: 40px;
      padding: 0;
    }
  }
}

.shoptopup-landing-design {
  font-family: 'Halyard-Regular';
  @media only screen and (max-width: 600px) {
    text-align: center;
  }

  a {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  .app-buttons {
    display: flex;
    justify-content: space-between;
    width: 25rem;
    margin-top: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-evenly;
    }
    .app {
      width: 191.79px;
      height: 55px;
      border-radius: 5px;
      justify-content: space-between;

      @media only screen and (max-width: 600px) {
        width: 180px;
        height: 51.5px;
      }
    }
  }

  .introduction {
    background-image: url('./assets/polkadots.svg');
    padding-top: 4rem;
    height: 64rem;
    display: block;
    overflow: hidden;
    background-repeat: revert;

    .img-phone-one {
      width: 33rem;
      display: block;
      @media only screen and (max-width: 600px) {
        margin-top: 4rem;
        margin-left: 8px;
      }
    }
    @media only screen and (max-width: 600px) {
      .row {
        flex-wrap: wrap-reverse;
      }
    }
    .introduction-text {
      color: $themeBlack;
      padding: 4rem 3rem;
      @media only screen and (max-width: 600px) {
        padding: 7px;
      }
      h1 {
        font-size: 61px;
        line-height: 105%;
        letter-spacing: 0.01em;
        font-weight: 500;
        width: 32rem;

        @media only screen and (max-width: 600px) {
          font-size: 41px;
          width: 100%;
          text-align: center;
        }
      }
      h6 {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.05em;
        font-weight: 300;
        width: 30rem;
        margin-top: 1rem;
        color: #6f7a8d;
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }

  .brands-container {
    margin-top: -28rem;
    position: relative;
    height: 25rem;

    @media only screen and (max-width: 600px) {
      height: auto;
      margin-top: -22rem;
    }

    .curve {
      width: 100%;

      &.top {
        @media only screen and (max-width: 600px) {
          position: relative;
          top: 6px;
        }
      }
      &.bottom {
        position: relative;
        background: white;
        @media only screen and (max-width: 600px) {
          bottom: 10px;
        }
      }
    }

    .brands {
      background: #f5f9fe;
      padding: 3rem;

      @media only screen and (max-width: 600px) {
        padding: 0;
      }

      .brands-list {
        padding-top: 5rem;
        @media only screen and (max-width: 600px) {
          flex-wrap: wrap;
          padding-top: 4rem;
        }
        h3 {
          font-size: 32px;
          line-height: 105%;
          letter-spacing: 0.01em;
        }
        h6 {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          font-weight: 200;
          line-height: 1.7;
          width: 19rem;
          margin-top: 1rem;
          color: #6f7a8d;
          @media only screen and (max-width: 600px) {
            padding: 0 1rem;
            width: 100%;
          }
        }
        .brand-logos {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          margin-top: -10px;
          align-items: center;
          @media only screen and (max-width: 600px) {
            margin-top: 15px;
          }
          p {
            margin-left: 5rem;
            flex: 1 75px;
            @media only screen and (max-width: 600px) {
              margin-left: 0;
            }
            img {
              width: 4rem;
              margin-bottom: 2rem;
              &.imgx3 {
                width: 7rem;
              }
              &.imgx2 {
                width: 6rem;
              }
              &.imgx1 {
                width: 5rem;
              }

              @media only screen and (max-width: 600px) {
                width: 3rem;
                margin-bottom: 2rem;
                &.imgx3 {
                  width: 6rem;
                }
                &.imgx2 {
                  width: 5rem;
                }
                &.imgx1 {
                  width: 4rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .info-section {
    margin-top: 6rem;

    @media only screen and (max-width: 600px) {
      margin-top: 4rem;
    }
    .info-image {
      border: 1px solid #eaedf5;
      text-align: center;
      width: 30rem;
      border-radius: 32px;
      overflow: hidden;
      box-shadow: 0px 20px 14px -10px rgba(0, 37, 91, 0.08);
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      img {
        width: 25rem;
        margin-left: 15px;
        padding: 0 5px;
        @media only screen and (max-width: 600px) {
          width: 18rem;
        }
      }
    }

    &.left {
      .info-image {
        margin-left: 4rem;

        @media only screen and (max-width: 600px) {
          margin: 0;
        }
      }
    }

    .info-text {
      padding: 3rem 5rem;
      @media only screen and (max-width: 600px) {
        padding: 7px 25px;
        margin-top: 35px;
      }
      h1 {
        width: 27rem;
        font-weight: normal;
        font-size: 48px;
        line-height: 105%;
        letter-spacing: 0.01em;

        @media only screen and (max-width: 600px) {
          width: 100% !important;
          font-size: 32px;
          line-height: 105%;
          text-align: center;
          letter-spacing: 0.01em;
          margin: 20px 0px;
        }
      }
      h6 {
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
        font-weight: 200;
        width: 31rem;
        margin-top: 1.3rem;
        color: #0c2344;
        @media only screen and (max-width: 600px) {
          width: 100% !important;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
        }
      }
      .list {
        margin-top: 1.4rem;
        li {
          list-style: none;
          margin-top: 10px;
          color: #6f7a8d;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;

          .mdi {
            background: rgba(0, 92, 255, 0.08);
            margin-right: 1rem;
            font-size: 12px;
            padding: 1px 3px;
            color: $themeBlue;
            border-radius: 5px;
          }
        }
      }
    }
    .action {
      margin-top: 1.4rem;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      a {
        color: $themeBlue;
        font-weight: 400;
      }
    }
  }

  .shop-capital {
    margin-top: 8rem;
    @media only screen and (max-width: 600px) {
      margin-top: 3rem;
    }
  }

  .inventory {
    h1 {
      width: 29rem !important;
      font-weight: 800;
    }
    .row {
      flex-wrap: wrap-reverse;
    }
  }

  .cashless-suppliers {
    h1 {
      width: 21rem !important;
    }
  }

  .info-retailers {
    .retailers-text {
      color: white;
      border-radius: 10px;
      background: $themeBlack;
      padding: 4rem;
      background-image: url(assets/polkadots-white.svg);

      @media only screen and (max-width: 600px) {
        margin: 0;
        padding: 3rem 20px;
      }

      h1 {
        font-weight: 600;
        font-size: 72px;
        line-height: 105%;
        text-align: center;
        letter-spacing: 0.01em;
        margin: 1rem 0;
        color: white;

        @media only screen and (max-width: 600px) {
          font-size: 33px;
        }
      }
      h6 {
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        font-weight: 300;
        padding: 0 30%;
        color: #6f7a8d;

        @media only screen and (max-width: 600px) {
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 1.5;
        }
      }

      .app-buttons {
        width: 100%;
        justify-content: center;
        @media only screen and (max-width: 600px) {
          padding: 0;
          justify-content: space-between;
        }
        .app {
          background: #ffffff;
          color: $themeBlack;
          margin: 5px;
          @media only screen and (max-width: 600px) {
            width: 160px;
          }
        }
      }
    }
  }
  .shoptopup-faqs {
    margin-top: 5rem;
    width: 1050px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
    h2 {
      font-size: 48px;
      line-height: 105%;
      letter-spacing: 0.01em;
      color: #0c2344;
      @media only screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
    .dropdown-list {
      margin-top: 3rem;
      text-align: left;
      .drop-col {
        padding: 35px;
        background: #f5f9ff;
        border-radius: 16px;
        margin-bottom: 1rem;
        a {
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.01em;
          color: #0c2344;
          display: flex;
          justify-content: space-between;
          &[aria-expanded='true'] {
            .mdi-chevron-down {
              transform: rotate(180deg);
            }
          }
          @media only screen and (max-width: 600px) {
            font-size: 18px;
          }
        }
        p {
          font-size: 18px;
          line-height: 160%;
          letter-spacing: 0.01em;
          color: #6f7a8d;
          font-weight: 300;
          margin-top: 15px;
          @media only screen and (max-width: 600px) {
            font-size: 15px;
          }

          a {
            color: $themeBlue;
            font-weight: 400;
            font-size: inherit;
            display: initial;
            line-height: initial;

            &.watch {
              color: $themeBlack;
            }
          }
        }
      }
    }
  }
  .footer {
    background: rgba(0, 92, 255, 0.04);
    margin-top: 5rem;
    padding: 4rem;
    img {
      width: 6rem;
    }
    .copyright-text {
      margin-top: 1rem;
      color: #6f7a8d;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
    .footer-menu {
      display: flex;
      justify-content: space-evenly;
      font-weight: 200;
      margin-top: 20px;
      li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #0c2344;
      }
    }
  }
}

.terms {
  @media only screen and (max-width: 600px) {
    padding: 10px;
  }

  a {
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth transition effect */

    &:hover {
      cursor: pointer;
      // color: #f49b3b;
    }
  }

  .title {
    h5 {
      font-weight: normal;
      font-size: 22px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: $themeBlack;
      margin-top: 1rem;
      text-align: center;
    }
    margin-bottom: 3rem;
  }
  .section-title {
    font-size: 20px;
    line-height: 105%;
    font-weight: 400;
    text-transform: uppercase;
  }

  .sub-title {
    font-weight: 100;
    text-transform: uppercase;
    margin: 1rem 0;
  }

  .contract-text {
    font-size: 17px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #6f7a8d;
    font-weight: 300;

    .key {
      font-weight: 300;
      text-transform: uppercase;
      color: $themeBlack;
    }
  }

  .introduction {
    span {
      font-size: 16px;
      line-height: 175%;
      letter-spacing: 0.01em;
      color: #6f7a8d;
      font-weight: 200;
    }
  }

  .contract-section {
    margin-bottom: 2rem;
  }

  .header {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .list {
    position: fixed;
    top: 6%;
    right: 0;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    .terms-item {
      list-style: none;
      margin: 2rem 1rem;
      border-left: 1px solid #eaedf5;
      overflow: auto;
      height: 600px;
      @include scrollbar-style;

      li {
        margin-top: 1rem;
        font-size: 15px;
        line-height: 105%;
        letter-spacing: 0.01em;
        color: #005cff;
        font-weight: 300;

        a {
          text-decoration: none;
          &.active {
            color: $themeBlack !important;
            font-weight: 400;
          }
        }
      }
    }
  }
}
