a {
  &:hover {
    color: #fff;
  }
}
.stu-drive {
  font-family: 'Sora', sans-serif;
  &_top-section {
    background: url(../assets/imgs/Landing-page.png) no-repeat;
    min-height: 588px;
    position: relative;
    background-size: cover;

    &_navigation {
      width: 100%;
      background: white;
      height: auto;
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      padding: 8px 1.05rem;
      align-items: center;

      @media only screen and (max-width: 768px) {
        position: fixed;
        left: 0;
        margin: 0;
        border-radius: 0;
        z-index: 1000;
        padding: 1rem 0.7rem;
      }

      &_logo-side {
        display: flex;
        align-items: center;
        span {
          height: 30px;
          width: 0.3px;
          outline: 0.1px solid #eaedf5;
          margin: 1rem 15px;
        }

        h4 {
          color: #0c2344;
          font-weight: 800;
          margin-bottom: 0;
          font-size: 21px;
        }

        @media only screen and (max-width: 768px) {
          justify-content: center;

          span {
            display: none !important;
          }

          h4 {
            display: none !important;
          }
        }
      }

      &_button {
        color: white;
        font-size: 15px;
        font-weight: normal;
        background-color: #005cff;
        border-radius: 0.5rem;
        text-decoration: none;
        padding: 10px 16px;
        width: 185px;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          font-size: 14px;
          width: 165px;
        }
      }
    }

    &_body {
      padding: 10rem 4rem;

      @media only screen and (max-width: 768px) {
        padding: 9rem 0;
      }

      &_left {
        color: #fff;
        position: absolute;
        top: 59%;
        transform: translate(0, -50%);
        width: 42%;

        @media only screen and (max-width: 768px) {
          position: initial;
          width: 100%;
          transform: initial;

          h1 {
            font-size: 45px !important;
            line-height: 55px !important;
            text-align: center !important;
          }

          h5 {
            width: 100% !important;
            font-size: 20px !important;
            text-align: center !important;
          }
        }

        h5 {
          width: 450px;
          line-height: 160%;
          font-size: 23px;
        }

        h1 {
          font-weight: 800;
          font-size: 50px;
          line-height: 67px;
        }
      }

      &_right {
        width: 80%;
        margin: auto;
        background: #fff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
        border-radius: 16px;
        padding: 20px 0px;
        float: right;

        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-top: 2rem;
        }

        h4 {
          padding: 0 2rem;
        }

        &-form {
          font-size: 14px;
          padding: 0 2rem;
        }

        h4 {
          font-size: 21px;
        }

        input,
        select {
          margin-top: 5px;
          font-size: 14.5px;
          height: 45px;
          border: 1px solid #eaedf5;

          &::placeholder {
            font-weight: 300;
          }
        }

        .row {
          margin-bottom: 1rem;
        }

        &_form-button {
          width: 100%;
          margin: 2rem 0;
          padding: 10px 78px;
          background: #005cff;
          border-radius: 8px;
          border: none;
          color: #fff;
          height: 55px;
          margin-bottom: 1rem;

          img {
            margin-left: 15px;
          }

          &:disabled {
            background-color: #aeb1b6;
          }
        }
      }
    }
  }

  &_section-two {
    padding: 7rem 0;
    background-color: #f8f8fa;

    @media only screen and (max-width: 768px) {
      padding: 4rem 0;
    }

    .row {
      margin-bottom: 2.5rem;
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    &_title {
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      color: #0c2344;
      margin-bottom: 3.5rem;
      @media only screen and (max-width: 768px) {
        margin-left: 1rem;
      }
    }
    &_howto {
      @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
        padding-left: 2rem;
        &_icon-wrapper {
          width: 11.5vw !important;
          height: 11.5vw !important;
        }
      }
      &_icon-wrapper {
        width: 3.5vw;
        height: 3.5vw;
        background-color: #c9c9d5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          position: relative;
          width: 50%;
          height: 50%;
        }
      }

      &_point-title {
        margin: 1rem 0;
        color: #0c2344;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
      }

      &_point-text {
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #6f7a8d;
        font-weight: 400;
        width: 90%;
      }
    }
  }

  &_section-three {
    padding: 7rem 0;

    @media only screen and (max-width: 768px) {
      padding: 5rem 2rem;
    }
    &_title {
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      color: #0c2344;
      margin-bottom: 3.5rem;
      @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
    &_cars {
      display: flex;
      max-width: 100%;
      overflow: scroll;
      white-space: nowrap;

      &_wrapper {
        width: 400px;
        height: 370px;
        background-color: #f8f8fa;
        border-radius: 0.75rem;
        padding: 3rem;
        text-align: center;
        margin-right: 3rem;

        &:last-child {
          margin-right: 0;
        }

        @media only screen and (max-width: 768px) {
          width: 340px;
          height: 430px;
          padding: 2rem;
          margin: 2px;
          margin-right: 10px;

          &:last-child {
            margin-right: 5px !important;
          }

          &_name {
            font-size: 23px !important;
          }
        }

        &_name {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #0c2344;
          margin-bottom: 2rem;
          text-align: initial;
        }

        &_perks {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;

          &-type {
            p {
              color: #6f7a8d;
              font-size: 14px;
              font-weight: 400;
              margin-top: 5px;
              margin-bottom: 0;
            }
          }
        }

        &_btn-view {
          padding: 10px 78px;
          margin-top: 2.5rem;
          background: #005cff;
          border-radius: 8px;
          border: none;
          color: #fff;

          img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_section-four {
    background-color: #0c2344;
    padding: 7rem 0;

    @media only screen and (max-width: 768px) {
      padding: 5rem 1.25rem;
    }

    &_title {
      color: #fff;
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }

    &_arrowups {
      margin-top: 2rem;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    &_benefits {
      color: white;

      .row {
        margin-bottom: 3rem;
        @media only screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      &_icon-wrapper {
        width: 3.5vw;
        height: 3.5vw;
        background: #ffffff29;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 768px) {
          width: 13.5vw;
          height: 13.5vw;
        }
      }
      img {
        position: relative;
        width: 50%;
        height: 50%;
      }

      &_title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        display: block;
        margin: 1rem 0;
      }

      &_text {
        color: #eaedf5;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: 0.01em;

        @media only screen and (max-width: 768px) {
          margin-bottom: 3rem;
        }
      }
    }
  }

  &_section-five {
    background-color: #eaedf5;
    padding: 10rem 0;
    text-align: center;

    @media only screen and (max-width: 768px) {
      padding: 5rem 0;
      padding-bottom: 9rem;
    }

    &_title {
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      color: #0c2344;
    }

    &_text {
      font-size: 24px;
      line-height: 160%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #6f7a8d;
      width: 43%;
      margin: auto;
      margin-top: 10px;

      @media only screen and (max-width: 768px) {
        width: 90%;
        font-size: 21px;
      }
    }

    &_button {
      background: #005cff;
      color: #fff;
      padding: 15px 78px;
      border-radius: 8px;
      margin-top: 45px;
      position: relative;
      top: 45px;
      text-decoration: none;
      font-weight: 600;

      img {
        margin-left: 15px;
        position: relative;
        top: -1px;
      }
    }
  }

  &_footer {
    padding: 5rem;
    @media only screen and (max-width: 768px) {
      padding: 2rem;
      margin: 3rem 0px;
    }
    &_right {
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #0c2344;
      font-weight: 300;
      @media only screen and (max-width: 768px) {
        margin: 1rem;
        text-align: center;
        margin-top: 3rem;
      }
    }

    &_left {
      &-brand {
        display: flex;
        align-items: center;
        span {
          height: 30px;
          width: 0.3px;
          outline: 0.1px solid #eaedf5;
          margin: 1rem 15px;
        }

        h4 {
          color: #0c2344;
          font-weight: 800;
          margin-bottom: 0;
          font-size: 21px;
        }

        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
      }

      &_links {
        display: flex;
        margin-top: 1.5rem;
        a {
          margin-right: 1rem;
          text-decoration: none;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #0c2344;
        }
        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
      }
    }
  }
}
